
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class CarouselConfigurationCard extends Vue {
        // Properties
        @Prop() private readonly type!: string;
        @Prop() private readonly resolution!: string;
        @Prop() private readonly horizontalFOV!: string;
        @Prop() private readonly verticalFOV!: string;
        @Prop() private readonly colorDepth!: string;
        @Prop() private readonly refreshRate!: string;
        @Prop() private readonly bandwidth!: string;
        @Prop() private readonly cardNumber!: string;

        // Data function
        public data() {
            return {
            };
        }
    }
