
    import CarouselConfigurationCard from "@/components/carousel_cards/carousel_configuration_card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            CarouselConfigurationCard
        },
    })

    // *****************************************************************************************************************
    export default class XrConfigurations extends Vue {
        // Data function
        public data() {
            return {
                headsetIconPathAndName:
                    require("@/assets/images/icon_headset.png"),
            };
        }
    }
